<template>
  <b-card
    title="Your Purchases"
  >
    <div
      class="d-flex justify-content-center mb-1"
    >
      <b-spinner
        v-if="isLoading"
      />
    </div>

    <b-card-body>
      <div class="d-flex justify-content-between  flex-wrap">

        <!-- sorting  -->
        <b-form-group class="mr-1 sortByInput mb-2">
          <b-input-group size="sm">
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
            >
              <template #first>
                <option value="">
                  Sort By
                </option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
            >
              <option :value="false">
                Ascending
              </option>
              <option :value="true">
                Descending
              </option>
            </b-form-select>
          </b-input-group>
        </b-form-group>

        <!-- filter -->
        <b-form-group class="mb-0">
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </b-card-body>

    <div v-if="!isLoading">
      <b-table
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        striped
        responsive
        show-empty
        class="mb-0"
        @filtered="onFiltered"
      >

        <template #empty>
          <div class="empty_banner">
            No Purchases yet
          </div>
        </template>
        <template #cell(show_details)="row">

          <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
          <b-form-checkbox
            v-model="row.detailsShowing"
            @change="row.toggleDetails"
          >
            {{ row.detailsShowing ? 'Hide' : 'Show' }}
          </b-form-checkbox>
        </template>

        <!-- full detail on click -->
        <template #row-details="row">
          <b-card>
            <div class="mb-2">
              <strong>Products</strong>
            </div>

            <b-row
              v-for="(data,index) in row.item.products"
              :key="index"
              class="mb-2"
            >
              <b-col

                md="4"
                class="mb-1"
              >
                <strong>{{ index + 1 }}. {{ data }}</strong>

              </b-col>
            </b-row>

            <b-button
              size="sm"
              variant="outline-secondary"
              @click="row.toggleDetails"
            >
              Hide Details
            </b-button>
          </b-card>
        </template>

        <template #cell(avatar)="data">
          <b-avatar :src="data.value" />
        </template>

        <template #cell(date)="data">
          {{ new Date(data.value.seconds * 1000) | formatDate }}
        </template>

        <template #cell(status)="data">
          <b-badge :variant="status[1][data.value]">
            {{ status[0][data.value] }}
          </b-badge>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0 mt-2">

        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="6"
          label-align="center"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </div>

  </b-card>
</template>

<script>

import {
  BTable, BFormCheckbox, BButton, BCard, BRow, BCol, BAvatar, BBadge, BSpinner, BPagination, BFormGroup,
  BInputGroupAppend,
  BFormSelect,
  BInputGroup,
  BFormInput,
} from 'bootstrap-vue'
import store from '@/store'
import { codeRowDetailsSupport } from './code'

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    BSpinner,
    BPagination,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
  },
  data() {
    return {
      perPage: 10,
      pageOptions: [5, 10, 20, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortOptions: [],
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: ['show_details', { key: 'ref', label: 'Reference' }, { key: 'order_id', label: 'Order Id' }, { key: 'date', label: 'Date' }, { key: 'method', label: 'method' }, { key: 'status', label: 'status' }, { key: 'subtotal', label: 'subtotal' }, { key: 'total', label: 'total' }],
      /* eslint-disable global-require */
      items: [
      ],
      /* eslint-disable global-require */
      status: [{
        Success: 'Success', Error: 'Error', null: 'N/A',
      },
      {
        None: 'light-primary', Success: 'light-success', Error: 'light-danger', null: 'light-warning', 5: 'light-info',
      }],
      codeRowDetailsSupport,
      isLoading: false,
    }
  },
  computed: {
    loadingStatus() {
      return this.$store.getters['loading/loadingStatus']
    },
  },
  watch: {
    loadingStatus(payload) {
      this.isLoading = payload.isLoading
    },
  },
  created() {
    this.$store.dispatch('purchases/fetchAlluserPurchases').then(() => {
      this.items = store.getters['purchases/purchases'].map((item, index) => ({
        ref: item.ref,
        index: index + 1,
        discount: item.discount,
        date: item.date,
        products: item.products,
        method: item.method,
        order_id: item.order_id,
        status: item.status,
        siteName: item.siteName,
        subtotal: item.subtotal,
        total: item.total,
      }))
      this.totalRows = this.items.length
    })
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
  metaInfo: {
    // Overwrite the SEO title.
    title: 'Purchases',
  },
}
</script>

<style lang="scss">
.empty_banner{
  width:100%;
  text-align: center !important;
  justify-content: center;
}
</style>
